import { getDomainWithoutWWWPrefix } from '@zyro-inc/site-modules/utils/domainUtils';
import {
	getCookie,
	setCookie,
} from '@zyro-inc/site-modules/utils/cookies';
import {
	computed,
	ref,
} from 'vue';
import { addDocumentElements } from '@/utils/addDocumentElements';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export const useCookieBanner = () => {
	const router = useRouter();
	const store = useStore();
	const domainNameWithoutWww = getDomainWithoutWWWPrefix(window.location.hostname);
	const cookieName = `z-cookies-consent-${domainNameWithoutWww}`;
	const cookieConsent = ref(getCookie(cookieName));
	const hasUserConsentedCookieBanner = computed(() => !!cookieConsent.value);
	const hasUserAcceptedCookies = computed(() => cookieConsent.value === '1');

	const acceptCookies = () => {
		setCookie(cookieName, 1, 365);
		cookieConsent.value = '1';

		addDocumentElements({
			siteData: store?.state.website,
			path: router.currentRoute.value.path,
		});
	};

	const declineCookies = () => {
		setCookie(cookieName, 0, 1);
		cookieConsent.value = '0';
	};

	return {
		acceptCookies,
		declineCookies,
		hasUserConsentedCookieBanner,
		hasUserAcceptedCookies,
	};
};
